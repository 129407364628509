const errInvalidCoord =
	'Invalid Coordinate Sent. Latitude must be -90 to 90 and Longitude -180 to 180';

export function Free2DDeg(coord: string): number {
	//if (!isValidCoordinate(coord)) {
	//	throw new Error(errInvalidCoord + ' ' + coord);
	//} else {
	if (typeof coord === 'undefined' || coord === '') {
		return 0;
	} else {
		let dDecimalDegrees = 0;
		let iCommasCount = 0;
		let arr = []
		if (coord.includes(' ')) {
			arr = coord.split(' ');
		} else {
			arr = coord.split(',');
		}
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === '' || arr[i] === ' ') { 	//This is so the app doesn't crash when when user is still typing and types a , or a ' '
				return 0;							//This is because of the way split() works when breaking down strings
			}
		}
		//This is comma count + 1 because when you split a string there is two parts
		//so when splitting you will have comma count + 1
		iCommasCount = arr.length;
		if (iCommasCount > 0) {
			dDecimalDegrees = Math.abs(parseFloat(coord));
		}
		if (iCommasCount > 1) {
			dDecimalDegrees = dDecimalDegrees + Math.abs((parseFloat(arr[1]) / 60));
		}
		if (iCommasCount > 2) {
			dDecimalDegrees = dDecimalDegrees + Math.abs((parseFloat(arr[2]) / 3600));
		}

		//console.log("Value entered = " + coord + " value output " + dDecimalDegrees);
		
		return Number(dDecimalDegrees.toFixed(6));
	}
//	}
}

/*
 * Integer Degrees and Decimal Minutes
 */
export function coordToDegreesDecimalMinutes(coord: number): string {
	if (!isValidCoordinate(coord)) {
		throw new Error(errInvalidCoord);
	} else {
		const absDeg = Math.abs(coord);
		const degrees: string = Math.abs(Math.floor(coord)).toString();

		let coordMin = (absDeg - Math.floor(absDeg)) * 60;
		coordMin = Number(coordMin.toFixed(2));

		const result: string = (degrees + '°,' + coordMin).toString();

		return result;
	}
}

export function isValidLatLon(lat: number, lon: number): boolean {
	if (lat >= -90 || lat <= 90) return true;
	if (lon >= -180 || lon <= 180) return true;
	return false;
}

export function isValidCoordinate(coord: number): boolean {
	return isValidLatLon(coord, coord);
}