import { DataGridPro, GridColDef, GridDensityTypes, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';import { PageContainer } from '../components/PageContainer';
import { dcName, isDcNameValid } from '../models/SearchParams';
import { useGetIncidentSummaryDataQuery } from '../store/wildWebApi';

function CalanderYearComponent() {	
	const todaysDate = new Date();
	todaysDate.setHours(0);
	todaysDate.setMinutes(0);
	todaysDate.setSeconds(0);
	todaysDate.setMilliseconds(0);
	const [currDate] = useState<string>(todaysDate.toISOString())
	const [fromDate] = useState<string>(new Date(new Date().getFullYear(), 0, 0, 0, 0, 0, 0).toISOString())

	const { data: yearSummaryData, isLoading: summaryLoading} = useGetIncidentSummaryDataQuery({dcName: dcName, fromDate: fromDate, toDate: currDate}, {skip: !isDcNameValid})

	const [summaryData, setSummaryData] = useState<any[]>([])

	useEffect(() => {
		if (yearSummaryData) {
			setSummaryData(yearSummaryData)
		}
	}, [yearSummaryData])

	const columns: GridColDef[] = [
		{ 
			field: 'inc_type', 
			headerName: 'Incident Type', 
			width: 150 
		},
		{ 
			field: 'inc_count', 
			headerName: 'Incident Count', 
			width: 150 ,
		},
	];
	
	const toolbarControl = { Toolbar: GridToolbar }
	return (
        <PageContainer
			pageTitle='Calendar Year Summary'
		>
			<DataGridPro 
				initialState={{
				sorting: {sortModel: [{ field: 'date', sort: 'desc' }],},}}
				getRowId={(r) => r.inc_type}
				rows={summaryData} 
				columns={columns}
				getRowHeight={() => 'auto'}
				density={GridDensityTypes.Compact}
				headerHeight={30}
				hideFooter
				loading={summaryLoading}
				components={toolbarControl}
			/>
		</PageContainer>
	);

}
export const CalanderYearPage: React.FunctionComponent = React.memo(CalanderYearComponent);
