export interface IFireStatus {
    out?: string,
    contain: string,
    control: string,
}

export class FireStatus implements IFireStatus{
    out?: string;
    contain: string;
    control: string;
    constructor(obj: any){
        const parsed = JSON.parse(obj)
        this.out = parsed.out;
        this.contain = parsed.contain;
        this.control = parsed.control;
    }
}