import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { DrawerWidth } from '../Constants';
import { IAppContainerProps } from '../models/AppContainerProps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      fontStyle: 'italic'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: DrawerWidth,
        flexShrink: 0
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: DrawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    menuDrawer: {
      minWidth: '100px',
      marginRight: theme.spacing(5)
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0,
      padding: theme.spacing(0),
      height: '94vh'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: DrawerWidth
    }
  })
);

function AppContainerComponent (props: IAppContainerProps) {
  const classes = useStyles();

  return (
        <Container
            maxWidth={false}
            id='main'
            role={'main'}
            className={clsx(classes.content)}
        >
            {props.children}
        </Container>
  )
}

export const AppContainer: React.FunctionComponent<any> = React.memo(AppContainerComponent);
