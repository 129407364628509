import { FunctionComponent, memo } from "react";
import { TitleDateDisplay } from "./TitleDateDisplay";

interface PageContainerProps {
    pageTitle: string;
    children: any;
    centered?: boolean;
    showDate?: boolean;
}

function PageContainerComponent(props: PageContainerProps) {

    return (
        <div
            style={{
                height: 'calc(100% - 65px)',
                marginTop: '2%',
                marginRight: '5%',
                marginLeft: '5%'
            }}
        >
            <TitleDateDisplay label={props.pageTitle} showDate={!(props.showDate === false)}/>
            <div 
                style={
                    props.centered ? {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        alignItems: 'center'
                    } : {
                        height: 'inherit'
                    }
                }
            >
                {props.children}
            </div>
        </div>
    )
}

export const PageContainer: FunctionComponent<PageContainerProps> = memo(PageContainerComponent)
