import { memo, useEffect, useState } from "react";
import { OpenRecentIncidentsData } from "../models/DataSource";
import { RetrievedIncidentsData } from "../models/RetrievedData";
import { dateTimeFormatterTitleStr } from "../utils/dateUtils";

function TitleDateDisplayComponent(props: {label: string, showDate: boolean}){
    const [retrievedDate, setRetrievedDate] = useState<string>('');

    const { data:incidentsData } = OpenRecentIncidentsData();

	useEffect(() => {
		if (incidentsData && incidentsData[0].data != null){
			const retrieved_data = new RetrievedIncidentsData(incidentsData[0])
			setRetrievedDate(retrieved_data.retrieved)
		}
	}, [incidentsData]);
    
  	const title = `${props.label} ${retrievedDate && props.showDate ? dateTimeFormatterTitleStr(retrievedDate) : ''}`;

    return (
        <h3 style={{ marginTop: '15px', textAlign: 'center', marginBottom: '15px', fontFamily: 'sans-serif' }}>{title}</h3>
    )
}

export const TitleDateDisplay = memo(TitleDateDisplayComponent);