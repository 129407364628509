
const urlParams = new URLSearchParams(window.location.search as string);

const lowerParams = new URLSearchParams();

for (const [name, value] of urlParams) {
    lowerParams.append(name.toLowerCase(), value);
}
export const dcName =  (lowerParams.get('dc_name')?.toUpperCase() ?? '') as string;
export const isDcNameValid = (dcName: string) => {

  if (!dcName) return false;

  if (typeof dcName === "string") {
    const length = dcName.length;
    return length === 5 || length === 6;
  }
  return false;
  
}; 