import { IFireStatus, FireStatus } from "./FireStatus"
import { IFiscalData, FiscalData } from "./FiscalData"

export interface IIncidentsData {
  uuid: string,
  type: string,
  date: string,
  inc_num: string,
  name: string,
  acres: string,
  fuels: string,
  latitude: string,
  location: string,
  longitude: string,
  webComment: string,
  fire_status: IFireStatus,
  fiscal_data: IFiscalData,
  resources: string[]
  fire_num: string
}

export class IncidentsData implements IIncidentsData {
  uuid: string;
  type: string;
  date: string;
  inc_num: string;
  name: string;
  acres: string;
  fuels: string;
  latitude: string;
  location: string;
  longitude: string;
  webComment: string;
  fire_status: IFireStatus;
  fiscal_data: IFiscalData;
  resources: string[];
  fire_num: string;
  
  constructor(obj: any) {
    this.uuid = obj.uuid;
    this.type = obj.type;
    this.inc_num = obj.inc_num;
    this.name = obj.name;
    this.acres = obj.acres;
    this.fuels = obj.fuels;
    this.latitude = obj.latitude;
    this.location = obj.location;
    this.longitude = obj.longitude;
    this.webComment = obj.webComment;
    this.resources = obj.resources;
    this.fire_num = obj.fire_num;
    this.date = obj.date;
    this.fire_status = new FireStatus(obj.fire_status);
    this.fiscal_data = new FiscalData(obj.fiscal_data);
  }
}