
import { DataGridPro, GridDensityTypes, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { IncidentTypeDD } from '../components/IncidentTypeDD';
import { PageContainer } from '../components/PageContainer';
import { dcName } from '../models/SearchParams';
import incidentsColumns from '../components/IncidentsColumns';

function IncidentsByTypeComponent() {
	const [loadingPage, setLoadingPage] = useState<boolean>(false)
	const [filteredIncidents, setFilteredIncidents] = useState<any[]>([])
	const [type, setType] = useState<number>(0)
	
	const fetchData = async (params: any) => {
		const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/centers/${params.dcName}/incidents?typeId=${params.type}&fromDate=${params.fromDate}&toDate=${params.toDate}`)
		const data = await response.json()
		if (response.status < 200 || response.status >= 300) {
			setFilteredIncidents([])
		} else if (data && data[0]?.data) {
			setFilteredIncidents(data[0].data)
			setLoadingPage(false)
		} else {
			setFilteredIncidents([]);
			setLoadingPage(false);
		}
	}

	useEffect(() => {
		if (type) {
			const currDate = new Date()
			const fromDate = new Date();
			fromDate.setMonth(currDate.getMonth() - 2)
			fromDate.setHours(0,0,0,0);
			currDate.setHours(23,59,59,999);
			const params = {
				dcName: dcName,
				type: type,
				fromDate: fromDate.toISOString(),
				toDate: currDate.toISOString()
			}
			fetchData(params)
			//setLoadingPage(true)
		} else {
			setFilteredIncidents([])
		}
	},[type])
	
	const toolbarControl = { Toolbar: GridToolbar }
	
	return (
        <PageContainer
			pageTitle='Incidents by Type'
		>
            <div style={{marginLeft: '15px'}}>
                <IncidentTypeDD id={type} onChange={(e: any) => setType(e.target.value)} />
            </div>
            <DataGridPro 
                initialState={{ 
					sorting: {
						sortModel: [{ field: 'date', sort: 'desc' }]
					}
				}}
                getRowId={(r) => r.uuid}
                rows={filteredIncidents} 
                columns={incidentsColumns(false)} 
                getRowHeight={() => 'auto'}
                density={GridDensityTypes.Compact}
                headerHeight={30}
                hideFooter
                loading={loadingPage}
                components={toolbarControl}
            />
		</PageContainer>
	);

}
export const incidentTypesPage: React.FunctionComponent = React.memo(IncidentsByTypeComponent);
