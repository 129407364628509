import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createGenerateClassName } from '@material-ui/core/styles';

export const generateClassName = createGenerateClassName({
  // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
  // using MUI classes (e.g. `.MuiButton`) they will lose styles.
  // Make sure to convert them to use `styled()` or `<Box />` first.
  disableGlobal: true,
  // Class names will receive this seed to avoid name collisions.
  seed: 'mui-jss',
});

export const appTheme = createTheme({
	palette: {
		common: {
			black: '#000',
			white: '#fff',
		},
		primary: {
			main: '#5e5e5e',
		},
		secondary: {
			main: '#e5e5e5',
		},
		background: {
			default: '#fff',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		fontSize: 12,
		fontFamily: 'sans-serif'
	},
	props: {
		MuiSwitch: {
			size: 'small',
			color: 'secondary',
		},
	},
});

export const appThemeV5 = createThemeV5({
	palette: {
		common: {
			black: '#000',
			white: '#fff',
		},
		primary: {
			main: '#5e5e5e',
		},
		secondary: {
			main: '#e5e5e5',
		},
		background: {
			default: '#fff',
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		fontSize: 12,
		fontFamily: 'sans-serif',
	},
	// props: {
	// 	MuiSwitch: {
	// 		size: 'small',
	// 		color: 'secondary',
	// 	},
	// },
});

export const customTextFieldStyles = <GlobalStyles
	styles={{
		//outlined component
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			'&&':{
				marginTop: '0px',
				fontFamily: 'sans-serif'
			}
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			'&&': {
				transform: 'translate(12px, -7px) scale(0.75)',
				fontFamily: 'sans-serif'
			}
		},
		'& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
			'&&': {
				padding: '6px 8px',
				height: '1.6em',
				fontSize: '0.8571428571428571rem',
				fontFamily: 'sans-serif'
			}
		},
		//standard component
		'& .css-1480iag-MuiInputBase-root-MuiInput-root': {
			'&&': {
				marginTop: '15px',
				fontSize: '0.8571428571428571rem',
				fontFamily: 'sans-serif'
			}
		},
		'& .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root': {
			'&&': {
				transform: 'translate(0px, 1.5px) scale(0.75)'
			}
		}
	}}
/>

export const customSelectStyles = <GlobalStyles 
	styles={{
		//outlined select component
		'.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			'&&': {
				fontSize: '0.8571428571428571rem',
				fontFamily: 'sans-serif'
			}
		},
		'.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
			'&&': {
				padding: '7px 8px'
			}
		}
	}}
/>
