export function dateTimeFormatter(date: Date): string {
	const format = Intl.DateTimeFormat('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	});
	const formattedDate = format
		.format(date)
		.toString()
		.replace(':', '')
		.replace(',', '');

	return formattedDate;
}

export function dateTimeFormatterTitle(date: Date): string {
	const format = Intl.DateTimeFormat('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: true,
	});
	const formattedDate = format
		.format(date)
		.toString()
		.replace(',', '');

	return formattedDate;
}

export function dateTimeFormatterStr(dateStr: string): string | undefined {
	let outStr;
	if (dateStr) {
		const date = new Date(dateStr);
		if (!isNaN(date.getTime())) {outStr = dateTimeFormatter(date)}
	}
	return outStr;
}

export function dateTimeFormatterTitleStr(dateStr: string): string | undefined {
	let outStr;
	if (dateStr) {
		const date = new Date(dateStr + 'Z');
		if (!isNaN(date.getTime())) {outStr = dateTimeFormatterTitle(date)}
	}
	return outStr;
}