import { DataGridPro, GridColDef, GridDensityTypes, GridToolbar, GridValueGetterParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { PageContainer } from '../components/PageContainer';
import { dcName, isDcNameValid } from '../models/SearchParams';
import { useGetResourceDataQuery } from '../store/wildWebApi';

function ResourceStatusComponent() {

	const { data: resourcesData, isLoading: resourcesLoading } = useGetResourceDataQuery(dcName, {skip: !isDcNameValid})

	// code and is_public are available in the function if needed
	const columns: GridColDef[] = [
		{ 
			field: 'id', 
			headerName: 'ID', 
			width: 75,
			hide: true
		},
		{
			field: 'code',
			headerName: 'Code',
			width: 150
		},
		{ 
			field: 'description', 
			headerName: 'Resource', 
			width: 150 ,
		},
		{ 
			field: 'status', 
			headerName: 'Status', 
			width: 150 
		},
		{ 
			field: 'location', 
			headerName: 'Location', 
			width: 150 ,
		},
		{ 
			field: 'incident_identifiers', 
			headerName: 'Inc#', 
			valueGetter: (params: GridValueGetterParams) => {
				const identifier = params.row.incident_identifiers;
				let retVal = '';
				retVal = `${identifier.unit ? identifier.unit + '-' : ''}${identifier.incident_num ? identifier.incident_num : ''}`
				return retVal;
			},
			width: 150 ,
		},
		{ 
			field: 'incident_name', 
			headerName: 'Name', 
			width: 150 ,
		},
		// { 
		// 	field: 'is_public', 
		// 	headerName: 'On Wildweb', 
		// 	width: 150 ,
		// },

	];
	
	const toolbarControl = { Toolbar: GridToolbar }
	return (
        <PageContainer
			pageTitle='Resource Status'
		>
			<DataGridPro 
				initialState={{
					sorting: {
						sortModel: [{ field: 'code', sort: 'asc' }]
					}
				}}
				getRowId={(r) => r.id}
				rows={resourcesData ? resourcesData : []} 
				columns={columns}
				getRowHeight={() => 'auto'}
				density={GridDensityTypes.Compact}
				headerHeight={30}
				hideFooter
				loading={resourcesLoading}
				components={toolbarControl}
			/>
		</PageContainer>
	);

}
export const ResourceStatusPage: React.FunctionComponent = React.memo(ResourceStatusComponent);
