import React from 'react';
import {
	createStyles,
	Link,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import { changelogBaseUrl } from '../Constants';
import { PageContainer } from '../components/PageContainer';

const shortEnv = process.env.REACT_APP_ENVIRONMENT?.replace('OWF ','');
// eslint-disable-next-line no-useless-concat
const changelogPdfUrl = changelogBaseUrl + '/' + shortEnv + '/' + 'WildWeb_Release_Notes.pdf';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			padding: '.5em',
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			fontSize: 16,
			borderRadius: 3,
			'&:hover': {
				textDecoration: 'none',
				backgroundColor: '#b8b8b8',
				color: theme.palette.common.black
			}
		}
	})
);

function AboutPageComponent() {
	const classes = useStyles();

    function openReleaseNotes() {
        const win = window.open(changelogPdfUrl, '_blank');
        if (win != null) {
            win.focus();
        }
    }
	
	return (
        <PageContainer
			pageTitle={`About ${process.env.REACT_APP_SHORT_NAME?.toUpperCase()}`}
			showDate={false}
			centered={true}
		>
                <h3 style={{textAlign: 'center'}}>{process.env.REACT_APP_FULL_NAME}</h3>
                <h3>{process.env.REACT_APP_ENVIRONMENT} BUILD</h3>
                <h3>{process.env.REACT_APP_SHORT_NAME?.toUpperCase()} Version: {process.env.REACT_APP_VERSION}</h3>
                <Link
					component='button'
					title='View Release Notes'
					className={classes.button}
					onClick={() => openReleaseNotes()}
				>
					View Release Notes
				</Link>
		</PageContainer>
	);

}
export const AboutPage: React.FunctionComponent = React.memo(AboutPageComponent);
