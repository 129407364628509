import { FormControl, InputLabel, MenuItem, Select, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useGetIncidentTypesQuery } from '../store/wildWebApi';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			marginLeft: theme.spacing(0.1),
			marginRight: theme.spacing(0.1),
            marginBottom: theme.spacing(2)
		},
	})
);

function IncidentTypeDDComponent(props: any) {

    const classes = useStyles()

    const {onChange, id} = props

    const { data: types } = useGetIncidentTypesQuery();

    useEffect(() => {
		setCurrId(id ? id : 0);
	}, [id]);

    //const nonExpiredTtypes = types?.filter(type => type.isExpired);

    const [currId, setCurrId] = useState(0)

    function onChangeDD(event: React.ChangeEvent<any>) {
        setCurrId(event.target.value)
        if (onChange) {
			onChange(event);
		}
    }

    return(
        <FormControl
            variant='standard'
            size='small'
            className={classes.formControl}
        >
            <InputLabel id='incidentTypeLabel'> Type </InputLabel>
            <Select
                labelId='newIncidentTypelabel'
                id='newIncidentType'
                value={currId}
                onChange={onChangeDD}
                displayEmpty={true}
                autoWidth={true}
            >
                <MenuItem key={`title_none`} value={"0"}>
					None
				</MenuItem>
                {types?.map((type: any) => {
					return (
						<MenuItem key={`title_${type.typeId}`} value={type.typeId}>
							{type.desc}
						</MenuItem>
					);
                })}
            </Select>
        </FormControl>
    )
}

export const IncidentTypeDD: React.FunctionComponent<any> = React.memo(IncidentTypeDDComponent);