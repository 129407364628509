
import { DataGridPro, GridDensityTypes, GridToolbar } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import incidentsColumns from '../components/IncidentsColumns';
import { PageContainer } from '../components/PageContainer';
import { OpenRecentIncidentsData } from '../models/DataSource';
import { RetrievedIncidentsData } from '../models/RetrievedData';


function OpenIncidentsComponent() {
	const { data:incidentsData, isLoading:incidentsLoading} = OpenRecentIncidentsData();
	const [incidentData, setIncidentData] = useState<any[]>([])

	useEffect(() => {
		if (incidentsData && incidentsData[0].data != null) {
			const retrieved_data = new RetrievedIncidentsData(incidentsData[0]);	
			setIncidentData(retrieved_data.data);
		}
	}, [incidentsData])
	
	const toolbarControl = { Toolbar: GridToolbar }

	return (
		<PageContainer
			pageTitle='Open/Recent Incidents'
		>
			<DataGridPro 
				initialState={{
				sorting: {sortModel: [{ field: 'date', sort: 'desc' }],},}}
				getRowId={(r) => r.uuid}
				rows={incidentData} 
				columns={incidentsColumns(true)} 
				getRowHeight={() => 'auto'}
				density={GridDensityTypes.Compact}
				headerHeight={30}
				hideFooter
				loading={incidentsLoading}
				components={toolbarControl}
			/>
		</PageContainer>
	);
}

export const OpenIncidentsPage: React.FunctionComponent = React.memo(OpenIncidentsComponent);
