import { IIncidentsData, IncidentsData } from "./IncidentData";

export interface IRetrievedIncidentsData {
    retrieved: string,
    data: IIncidentsData[],
}

export class RetrievedIncidentsData implements IRetrievedIncidentsData {
    retrieved: string = '';
    data: IIncidentsData[] = [];
    constructor(raw_data: any) {
        const rid = raw_data as IRetrievedIncidentsData;
        //const incidents = [] as IIncidentsData[];
        rid.data.forEach(element => {
            this.data.push(new IncidentsData(element))
        });
       this.retrieved = rid.retrieved;
    }
}