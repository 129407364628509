/*
*   Constants.ts
*   --------------------------------------------------------------------------------
*   This file contains the constants used in the application. If the variable is specific to an environment, it needs put into the .env instead. This is only for variables 
*   that are not specific to an environment.
*   Factored some of these out of the main file to make it easier to maintain across environments.
*   To use the variables that have been factored out, look them up in the .env. file and insert into code: ${process.env.REACT_APP_variable_name}
*/
// factored to .env file export const AppName = 'WildCAD-E';
// factored to .env file export const AppNameFull = 'Wildland Computer-Aided Dispatch Enterprise';
//export const 2021_2022MUILicenseKey = '25912b8688172e5ad7d2de1aa429e920T1JERVI6Mjg0NTgsRVhQSVJZPTE2NjEwMjAyMzAwMDAsS0VZVkVSU0lPTj0x';
export const MUILicenseKey = 'bd3b22f3c37f2b8cb93e3eef43772d0bTz01MDQ0NSxFPTE2OTQxOTIxMzA0MTYsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=';
export const DrawerWidth = 240;
// factored to .env files export const DevBaseURL = 'https://6fgws7lgpd.execute-api.us-west-2.amazonaws.com/dev/';
export const CommittedResStatusId = 16;
export const OpenIncStatusId = 1;
export const RespondingResStatusId = 2;
export const AvailResStatusId = 18;
export const UnavailResStatusId = 6;
export const ComplexTypeId = 20;
export const WildfireTypeId = 2;
export const FalseAlarmTypeId = 21;
export const PrescribedFireTypeId = 12;
export const VehicleFireTypeId = 4;
export const StructureFireTypeId = 3;
export const LETypeId = 9;
export const NATypeId = 1;
export const DefaultTimerIntervalSec = 900;
export const DefaultCxLat = 43.6167;
export const DefaultCxLon = 116.2;
export const DefaultDiscAcres = 0.1;
export const DefaultTimerTitle = 'New';
export const LoginFailedMessage = 'Login failed.  Contact your administrator.'
// UNUSED export const IWFIRPRedirUrl = 'https://iwfirp-qa.nwcg.gov/oauth/authorize?client_id=WILDCADE-DEV&redirect_uri=https://dev.d3pxr57823g6db.amplifyapp.com/&response_type=code&state=abcd1234'
export const CenterAdminRole = 'WCCTRADMIN';
export const WildAdminRole = 'WCADMIN';
export const DispatchRole = 'WCDISPATCH';
export const owm_apikey = 'c4b9e785a67204213c7bdcf76b8bcac0'
// factored to .env files export const SpatialDataURL = 'https://6fgws7lgpd.execute-api.us-west-2.amazonaws.com/dev/gis/spatialData'
export const googleMapsAPIKey = 'AIzaSyDd33KTWE1LXN3RYO9O0EZwA77dyFiVf7k'
export const changelogBaseUrl="https://wildcad-public-assets.s3.us-west-2.amazonaws.com";
export const layersListOrder = {
    WildCAD: 4,
    Resources: 3,
    Incidents: 2,
    Other: 5,
    CustomLayers: 1
}

export const spatialDataColors = [
    'blue',
    'gold',
    'red',
    'green',
    'orange',
    'yellow',
    'violet',
    'gray',
    'black'
]

export function colorNameToHex(color:string)
{
    const colors:any = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
    "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
    "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
    "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
    "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
    "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
    "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
    "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
    "honeydew":"#f0fff0","hotpink":"#ff69b4",
    "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
    "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
    "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
    "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
    "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
    "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
    "navajowhite":"#ffdead","navy":"#000080",
    "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
    "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
    "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
    "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
    "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
    "violet":"#ee82ee",
    "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
    "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colors[color.toLowerCase()] != 'undefined')
        return colors[color.toLowerCase()];

    return false;
}
