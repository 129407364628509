import { FormControl, InputLabel, MenuItem, Select, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

//theme.spacing(1) = 8px
const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            formControl: {
                marginLeft: theme.spacing(0.1),
                marginRight: theme.spacing(0.1),
                marginBottom: theme.spacing(2)
            },
        })
    );

function MonthDropdownComponent(props: any) {
    
    const {onChange, id, onChangeData} = props
    const [currId, setCurrId] = useState(0)
    const [setCurrentMonthData] = useState({})

	useEffect(() => {
		setCurrId(id ? id : 0);
        onChangeData(incidentMonths[id])
	}, [id]);

    const getLastMonths = () => {
        const months = []
        const monthName = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var d = new Date();
        d.setDate(1);
        d.setMonth(d.getMonth() - 1)
        for (let i=0; i<=11; i++) {
            months.push({ 
                id: i, 
                month: monthName[d.getMonth()], 
                year: d.getFullYear(), 
                firstDay: new Date(d.getFullYear(), d.getMonth(), 1).toISOString(), //first day of current month
                lastDay: new Date(new Date(d.getFullYear(), d.getMonth() + 1, 0).setHours(23,59,59,999)).toISOString()}) 
            d.setMonth(d.getMonth() - 1);
        }
        return months
    }

    function onChangeDD(event: React.ChangeEvent<any>) {
        setCurrId(event.target.value)
        //setCurrentMonthData(incidentMonths[event.target.value])
        if (onChange) {
			onChange(event);
		}
    }

    //get the last 12 months
    const incidentMonths = getLastMonths()

    const classes = useStyles();
    return (
    <FormControl
        variant='standard'
        size='small'
        className={classes.formControl}
        >
        <InputLabel id='incidentTypeLabel'> Month </InputLabel>
        <Select
            label='label'
            displayEmpty={true}
            onChange={onChangeDD}
            value={currId}
            autoWidth={true}>
                <MenuItem key={`title_none`} value={"-1"}>
					None
				</MenuItem>
                {incidentMonths?.map((month) => {
                    return (    
                        <MenuItem key={`${month.month} ${month.year}`} value={month.id}>
                            {`${month.month} ${month.year}`}
                        </MenuItem>
                    )
                })}
         </Select>
    </FormControl>)
}

export const MonthDropdown: React.FunctionComponent<any> = React.memo(MonthDropdownComponent);