
import React from 'react';

import {
	createStyles,
	makeStyles,
	Theme
} from '@material-ui/core';



const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		gridWrapper: {
			width: '25em'
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		label: {
			marginTop: '15px',
			textAlign: 'center',
			marginBottom: '15px',
			fontFamily: 'sans-serif'
		},
		table: {
			borderCollapse: 'collapse'
		},
		td: {
			border: '1px solid black'
		},
		th: {
			border: '1px solid black',
			font: 'strong'
		}
	})
);


function CentersListComponent() {

	const classes = useStyles();

	const title = 'WildCAD-E Centers List ';

	return (
		<div className={classes.wrapper}>
			<h3 className={classes.label}>{title}</h3>
			<table className={classes.table}>
				<thead><tr><th className={classes.th}>WildCAD-E WildWeb Dispatch Center</th><th className={classes.td}><b>Updated</b></th><th className={classes.td}>Link</th></tr></thead>

				<tbody>
					<tr><td className={classes.td}>AKACDC Alaska Coastal Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AKACDC">AKACDC</a></td></tr>
					<tr><td className={classes.td}>AKCGFC Chugach National Forest Dispatch</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AKCGFC">AKCGFC</a></td></tr>
					<tr><td className={classes.td}>AKNFDC Northern Forestry Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AKNFDC">AKNFDC</a></td></tr>
					<tr><td className={classes.td}>AKTNFC Tongass National Forest Dispatch</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AKTNFC">AKTNFC</a></td></tr>

					<tr><td className={classes.td}>ALAIC Alabama Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ALAIC">ALAIC</a></td></tr>
					<tr><td className={classes.td}>ARAOC Arkansas-Oklahoma Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ARAOC">ARAOC</a></td></tr>
					<tr><td className={classes.td}>AZADC Arizona Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZADC">AZADC</a></td></tr>
					<tr><td className={classes.td}>AZFDC Flagstaff Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZFDC">AZFDC</a></td></tr>
					<tr><td className={classes.td}>AZPDC Prescott Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZPDC">AZPDC</a></td></tr>
					<tr><td className={classes.td}>AZPHC Phoenix Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZPHC">AZPHC</a></td></tr>
					<tr><td className={classes.td}>AZSDC Springerville Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZSDC">AZSDC</a></td></tr>
					<tr><td className={classes.td}>AZTDC Tucson Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZTDC">AZTDC</a></td></tr>
					<tr><td className={classes.td}>AZWDC Williams Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=AZWDC">AZWDC</a></td></tr>

					<tr><td className={classes.td}>CAANCC Angeles Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAANCC">CAANCC</a></td></tr>
					<tr><td className={classes.td}>CACCCC Central California Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CACCCC">CACCCC</a></td></tr>
					<tr><td className={classes.td}>CAGVCC Grass Valley Interagency Emergency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAGVCC">CAGVCC</a></td></tr>
					<tr><td className={classes.td}>CALPCC Los Padres Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CALPCC">CALPCC</a></td></tr>
					<tr><td className={classes.td}>CAMNFC Mendocino National Forest Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAMNFC">CAMNFC</a></td></tr>
					<tr><td className={classes.td}>CAMICC Modoc Interagency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAMICC">CAMICC</a></td></tr>
					<tr><td className={classes.td}>CANCIC North Coast Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CANCIC">CANCIC</a></td></tr>
					<tr><td className={classes.td}>CAONCC Northern California Geographic Area Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAONCC">CAONCC</a></td></tr>
					<tr><td className={classes.td}>CAOVCC Owens Valley Interagency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAOVCC">CAOVCC</a></td></tr>

					<tr><td className={classes.td}>CAPNFC Plumas Emergency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAPNFC">CAPNFC</a></td></tr>
					<tr><td className={classes.td}>CARICC Redding Interagency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CARICC">CARICC</a></td></tr>
					<tr><td className={classes.td}>CASBCC San Bernardino Interagency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CASBCC">CASBCC</a></td></tr>
					<tr><td className={classes.td}>CASDIC San Diego Interagency Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CASDIC">CASDIC</a></td></tr>
					<tr><td className={classes.td}>CASICC Sierra Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CASICC">CASICC</a></td></tr>
					<tr><td className={classes.td}>CASQCC Ash Mountain Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CASQCC">CASQCC</a></td></tr>
					<tr><td className={classes.td}>CAYICC Yreka Interagency Command Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAYICC">CAYICC</a></td></tr>
					<tr><td className={classes.td}>CASTCC Stanislaus Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CASTCC">CASTCC</a></td></tr>
					<tr><td className={classes.td}>CAYPCC Yosemite Emergency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CAYPCC">CAYPCC</a></td></tr>

					<tr><td className={classes.td}>COCRC Craig Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=COCRC">COCRC</a></td></tr>
					<tr><td className={classes.td}>CODRC Durango Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=CODRC">CODRC</a></td></tr>
					<tr><td className={classes.td}>COMTC Montrose Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=COMTC">COMTC</a></td></tr>
					<tr><td className={classes.td}>COFTC Fort Collins Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=COFTC">COFTC</a></td></tr>
					<tr><td className={classes.td}>COGJC Grand Junction Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=COGJC">COGJC</a></td></tr>
					<tr><td className={classes.td}>COPBC Pueblo Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=COPBC">COPBC</a></td></tr>

					<tr><td className={classes.td}>FLFIC Florida Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=FLFIC">FLFIC</a></td></tr>
					<tr><td className={classes.td}>GAGIC Georgia Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=GAGIC">GAGIC</a></td></tr>

					<tr><td className={classes.td}>IDBDC Boise Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDBDC">IDBDC</a></td></tr>
					<tr><td className={classes.td}>IDCDC Coeur d"Alene Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDCDC">IDCDC</a></td></tr>
					<tr><td className={classes.td}>IDCIC Central Idaho Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDCIC">IDCIC</a></td></tr>
					<tr><td className={classes.td}>IDEIC Eastern Idaho Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDEIC">IDEIC</a></td></tr>
					<tr><td className={classes.td}>IDGVC Grangeville Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDGVC">IDGVC</a></td></tr>
					<tr><td className={classes.td}>IDPAC Payette Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDPAC">IDPAC</a></td></tr>
					<tr><td className={classes.td}>IDSCC South Idaho Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=IDSCC">IDSCC</a></td></tr>

					<tr><td className={classes.td}>ILILC Illinois Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ILILC">ILILC</a></td></tr>
					<tr><td className={classes.td}>INIIC Indiana Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=INIIC">INIIC</a></td></tr>
					<tr><td className={classes.td}>KYKIC Kentucky Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=KYKIC">KYKIC</a></td></tr>
					<tr><td className={classes.td}>LALIC Louisiana Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=LALIC">LALIC</a></td></tr>
					<tr><td className={classes.td}>MIMIDC Michigan Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MIMIDC">MIMIDC</a></td></tr>
					<tr><td className={classes.td}>MNMNCC Minnesota Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MNMNCC">MNMNCC</a></td></tr>
					<tr><td className={classes.td}>MOMOC Missouri-Iowa Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MOMOC">MOMOC</a></td></tr>
					<tr><td className={classes.td}>MSMIC Mississippi Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MSMIC">MSMIC</a></td></tr>

					<tr><td className={classes.td}>MTBDC Billings Dispatch Center Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTBDC">MTBDC</a></td></tr>
					<tr><td className={classes.td}>MTBRC Bitterroot Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTBRC">MTBRC</a></td></tr>
					<tr><td className={classes.td}>MTDDC Dillon Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTDDC">MTDDC</a></td></tr>
					<tr><td className={classes.td}>MTGDC Great Falls Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTGDC">MTGDC</a></td></tr>
					<tr><td className={classes.td}>MTHDC Helena Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTHDC">MTHDC</a></td></tr>
					<tr><td className={classes.td}>MTKDC Kootenai Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTKDC">MTKDC</a></td></tr>
					<tr><td className={classes.td}>MTKIC Kalispell Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTKIC">MTKIC</a></td></tr>
					<tr><td className={classes.td}>MTLEC Lewistown Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTLEC">MTLEC</a></td></tr>
					<tr><td className={classes.td}>MTMCC Miles City Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTMCC">MTMCC</a></td></tr>
					<tr><td className={classes.td}>MTMDC Missoula Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=MTMDC">MTMDC</a></td></tr>

					<tr><td className={classes.td}>NCNCC North Carolina Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NCNCC">NCNCC</a></td></tr>
					<tr><td className={classes.td}>NHNEC Northeastern Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NHNEC">NHNEC</a></td></tr>

					<tr><td className={classes.td}>NMABC Albuquerque Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NMABC">NMABC</a></td></tr>
					<tr><td className={classes.td}>NMADC Alamogordo Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NMADC">NMADC</a></td></tr>
					<tr><td className={classes.td}>NMSDC Silver City Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NMSDC">NMSDC</a></td></tr>
					<tr><td className={classes.td}>NMSFC Santa Fe Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NMSFC">NMSFC</a></td></tr>
					<tr><td className={classes.td}>NMTDC Taos Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NMTDC">NMTDC</a></td></tr>

					<tr><td className={classes.td}>NVCNC Central Nevada Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NVCNC">NVCNC</a></td></tr>
					<tr><td className={classes.td}>NVECC Ely Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NVECC">NVECC</a></td></tr>
					<tr><td className={classes.td}>NVEIC Elko Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NVEIC">NVEIC</a></td></tr>
					<tr><td className={classes.td}>NVSFC Sierra Front Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NVSFC">NVSFC</a></td></tr>
					<tr><td className={classes.td}>NVLIC Las Vegas Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=NVLIC">NVLIC</a></td></tr>

					<tr><td className={classes.td}>OR712C Grants Pass Unit Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=OR712C">OR712C</a></td></tr>
					<tr><td className={classes.td}>OR71C Medford Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=OR71C">OR71C</a></td></tr>
					<tr><td className={classes.td}>ORBIC Burns Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORBIC">ORBIC</a></td></tr>
					<tr><td className={classes.td}>ORBMC Blue Mountains Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORBMC">ORBMC</a></td></tr>
					<tr><td className={classes.td}>ORCOC Central Oregon Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORCOC">ORCOC</a></td></tr>
					<tr><td className={classes.td}>OREIC Eugene Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=OREIC">OREIC</a></td></tr>
					<tr><td className={classes.td}>ORJDCC John Day Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORJDCC">ORJDCC</a></td></tr>
					<tr><td className={classes.td}>ORLFC Lakeview Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORLFC">ORLFC</a></td></tr>
					<tr><td className={classes.td}>ORORC Oregon Department of Forestry State Headquarters Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORORC">ORORC</a></td></tr>

					<tr><td className={classes.td}>ORRICC Roseburg Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORRICC">ORRICC</a></td></tr>
					<tr><td className={classes.td}>ORRVC Rogue Valley Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORRVC">ORRVC</a></td></tr>
					<tr><td className={classes.td}>ORVAC Vale District Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=ORVAC">ORVAC</a></td></tr>

					<tr><td className={classes.td}>PAMACC Mid-Atlantic Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=PAMACC">PAMACC</a></td></tr>

					<tr><td className={classes.td}>SCSCC South Carolina Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=SCSCC">SCSCC</a></td></tr>
					<tr><td className={classes.td}>SCSRC Savannah River Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=SCSRC">SCSRC</a></td></tr>
					<tr><td className={classes.td}>SDGPC Great Plains Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=SDGPC">SDGPC</a></td></tr>

					<tr><td className={classes.td}>TNTNC Tennessee Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=TNTNC">TNTNC</a></td></tr>
					<tr><td className={classes.td}>TXTIC Texas Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=TXTIC">TXTIC</a></td></tr>

					<tr><td className={classes.td}>UTCDC Color Country Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=UTCDC">UTCDC</a></td></tr>
					<tr><td className={classes.td}>UTMFC Moab Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=UTMFC">UTMFC</a></td></tr>
					<tr><td className={classes.td}>UTNUC Northern Utah Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=UTNUC">UTNUC</a></td></tr>
					<tr><td className={classes.td}>UTRFC Richfield Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=UTRFC">UTRFC</a></td></tr>
					<tr><td className={classes.td}>UTUBC Uintah Basin Interagency Fire Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=UTUBC">UTUBC</a></td></tr>

					<tr><td className={classes.td}>VAVIC Virginia Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=VAVIC">VAVIC</a></td></tr>

					<tr><td className={classes.td}>WACAC Colville Agency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WACAC">WACAC</a></td></tr>
					<tr><td className={classes.td}>WACCC Columbia Cascade Communications Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WACCC">WACCC</a></td></tr>
					<tr><td className={classes.td}>WACWC Central Washington Interagency Communication Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WACWC">WACWC</a></td></tr>
					<tr><td className={classes.td}>WANDC WAS-Northwest Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WANDC">WANDC</a></td></tr>
					<tr><td className={classes.td}>WANEC Northeast Washington Interagency Communications Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WANEC">WANEC</a></td></tr>
					<tr><td className={classes.td}>WAOLC Olympic Region Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WAOLC">WAOLC</a></td></tr>
					<tr><td className={classes.td}>WAPCC Pacific Cascade Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WAPCC">WAPCC</a></td></tr>

					<tr><td className={classes.td}>WAPSC Puget Sound Interagency Coordination Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WAPSC">WAPSC</a></td></tr>
					<tr><td className={classes.td}>WASPC South Puget Sound Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WASPC">WASPC</a></td></tr>

					<tr><td className={classes.td}>WIWIC Wisconsin Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WIWIC">WIWIC</a></td></tr>

					<tr><td className={classes.td}>WYCDC Cody Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WYCDC">WYCDC</a></td></tr>
					<tr><td className={classes.td}>WYCPC Casper Interagency Dispatch Center</td><td className={classes.td}>Current</td><td className={classes.td}><a target="_blank" rel="noopener noreferrer" href="/incidents?dc_name=WYCPC">WYCPC</a></td></tr>
				</tbody>
			</table>
		</div>
	);

}
export const CentersListPage: React.FunctionComponent = React.memo(CentersListComponent);
