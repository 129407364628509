
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter, Route} from 'react-router-dom';
import './App.css';
import { appTheme, appThemeV5, generateClassName } from './AppTheme';

import { globalStore } from './store/globalStore';

import { useState } from 'react';
import { AppContainer } from './layout/AppContainer';
import { AppDrawer } from './layout/AppDrawer';
import { AppHeader } from './layout/AppHeader';
import { isDcNameValid } from './models/SearchParams';
import { incidentMonthPage } from './pages/IncidentsByMonthPage';
import { incidentTypesPage } from './pages/IncidentsByTypePage';
import { CalanderYearPage } from './pages/calanderYearSummaryPage';
import { ResourceStatusPage } from './pages/resourceStatusPage';
import { OpenIncidentsPage } from './pages/OpenIncidentsPage';

import React from 'react';
import { AboutPage } from './pages/aboutPage';
import { CentersListPage } from './pages/CentersListPage';
import { Switch} from 'react-router-dom';

function App() {
  
  const urlParams = new URLSearchParams(window.location.search as string);
  const lowerParams = new URLSearchParams();
  for (const [name, value] of urlParams) {
    lowerParams.append(name.toLowerCase(), value);
   }
  const dcName =  (lowerParams.get('dc_name')?.toUpperCase() ?? '') as string;

  const fullPath = window.location.pathname.toLowerCase();
  
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);

   /* first thing, test if there is a dc_name, if so handle it */
  if (dcName) {
    if (isDcNameValid(dcName) && fullPath==="/") {
        window.location.href = "/incidents?dc_Name=" + dcName;
        return null;
    }
    if (!isDcNameValid(dcName)) {
       window.location.replace("/centers");
        return null;
    }
  }

    return (
      <Provider store={globalStore}>
        <BrowserRouter>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={appTheme}>
            <ThemeProviderV5 theme={appThemeV5}>
              { fullPath !== "/" && fullPath !== "/centers" && (
              <AppHeader
                appName={'wildcad'}
                menuDrawerOpen={menuDrawerOpen}
                setMenuDrawerOpen={setMenuDrawerOpen} 
              /> )}
                  <AppDrawer                     
                      menuDrawerOpen={menuDrawerOpen}
                      setMenuDrawerOpen={setMenuDrawerOpen} 
                  />
             
                <AppContainer>
                  <Switch>
                    <Route path='/centers' component={CentersListPage} /> 
                    <Route path='/incidents' component={OpenIncidentsPage} />
                    <Route path='/incidentByType' component={incidentTypesPage} />
                    <Route path='/incidentByMonth' component={incidentMonthPage} />
                    <Route path='/resourceStatus' component={ResourceStatusPage} />
                    <Route path='/calendarYear' component={CalanderYearPage} />
                    <Route path='/about' component={AboutPage} />
                    <Route path='/' component={() => {
                        window.location.replace('/centers');
                        return null;
                    }}/> 
                  </Switch>
                </AppContainer>
            </ThemeProviderV5>
          </ThemeProvider>
        </StylesProvider>
        </BrowserRouter>
      </Provider>

    )

  }

export default App;
