export interface IFiscalData {
    wfdssunit: string,
    inc_num: string,
	fs_job_code: string,
	fire_code: string,
	fs_override: string,
	state_fiscal_code: string,
	fiscal_comments: string,
    abcd_misc: string
}

export class FiscalData implements IFiscalData{
    wfdssunit: string;
    inc_num: string;
	fs_job_code: string;
	fire_code: string;
	fs_override: string;
	state_fiscal_code: string;
	fiscal_comments: string;
    abcd_misc: string;
    constructor(obj: any){
		const parsed = JSON.parse(obj);
        this.wfdssunit = parsed.wfdssunit;
        this.inc_num = parsed.inc_num;
        this.fs_job_code = parsed.fs_job_code;
		this.fire_code = parsed.fire_code;
        this.fs_override = parsed.fs_override;
        this.state_fiscal_code = parsed.state_fiscal_code;
        this.fiscal_comments = parsed.fiscal_comments;
        this.abcd_misc = parsed.abcd_misc;
    }
}