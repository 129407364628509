import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';
import { wildcadApi } from './wildWebApi';

const wrappedReducer = combineReducers({
  [wildcadApi.reducerPath]: wildcadApi.reducer,
});

export const globalStore = configureStore({reducer: wrappedReducer,
  middleware: (gDM) => gDM({immutableCheck: false, serializableCheck: false})
  .concat(wildcadApi.middleware)
});


export type RootState = ReturnType<typeof globalStore.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
