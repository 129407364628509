import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ICalandarSummaryData } from '../models/CalandarSummaryModel';
import { dcName } from '../models/SearchParams';
import { ITypeMonthData } from '../models/ITypeMonthData';

export const wildcadApi = createApi({
	reducerPath: 'wildcadApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_ENDPOINT,
	}),

	endpoints: (builder) => ({
		getWildWebCenters: builder.query<any[], string>({
			query: () => `/centers`,
		}),
		getIncidentsData: builder.query<any[], string>({
			query: (dc_name) => `/centers/${dcName}/incidents`,
		}),
		getIncidentSummaryData: builder.query<any[], ICalandarSummaryData>({
			query: (params: ICalandarSummaryData) => `/centers/${dcName}/incidentSummary?fromDate=${params.fromDate}&toDate=${params.toDate}`,
		}),
		getResourceData: builder.query<any[], string>({
			query: (dc_name) => `/centers/${dcName}/resources`,
		}),
		getIncidentTypes: builder.query<any[], void>({
			query: () => `/incidentTypes`,
		}),
		getIncidentsByType: builder.query<any[], ITypeMonthData>({
			query: (params: ITypeMonthData) =>  `/centers/${dcName}/incidents?typeId=${params.type}&fromDate=${params.fromDate}&toDate=${params.toDate}`
		}),
		getIncidentsByMonth: builder.query<any[], ITypeMonthData>({
			query: (params: ITypeMonthData) =>  `/centers/${dcName}/incidents?fromDate=${params.fromDate}&toDate=${params.toDate}`
		})

	}),

});

export const {
	useGetWildWebCentersQuery,
	useGetIncidentsDataQuery,
	useGetIncidentSummaryDataQuery,
	useGetResourceDataQuery,
	useGetIncidentTypesQuery,
	useGetIncidentsByTypeQuery,
	useGetIncidentsByMonthQuery

} = wildcadApi;
