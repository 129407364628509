import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import {
	createStyles, makeStyles,
	Theme
} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import { DrawerWidth } from '../Constants';
import { dcName } from '../models/SearchParams';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			color: 'white',
			background: '#5e5e5e',
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		toolBar: {
			display: 'flex',
			flexDirection: 'row',
			alignContent: 'center',
			justifyContent: 'space-between',
			width: '100%',
			paddingRight: '20px',
			paddingLeft: '20px'
		},
		customHeight: {
			minHeight: '14px',
		},
		appBarShift: {
			width: `calc(100% - ${DrawerWidth}px)`,
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: DrawerWidth,
		},
		menuButton: {
			marginLeft: theme.spacing(0),
			marginRight: theme.spacing(2),
		},
		toolbar: theme.mixins.toolbar,
		appNameFull: {
			marginTop: theme.spacing(1),
			fontStyle: 'italic',
			fontWeight: 'bold',
			fontSize: '1.2em',
			color: `${theme.palette.common.white}!important`,
		}
	})
);

export interface AppHeaderProps {
	appName: string;
	menuDrawerOpen: boolean;
	setMenuDrawerOpen: any;
	hideWhen?: string[];

	//   onUserSelected: (userRecord: IUser) => void;
	//   user: IUser | undefined;
}

/*
 * @function AppHeader
 * @param <AppHeaderProps> props
 * @return <JSX.Element>
 * builds out AppHeader component
 */
function AppHeaderComponent(props: AppHeaderProps): JSX.Element {
	const {
		setMenuDrawerOpen,
		// onUserSelected,
		// user
	} = props;

	function handleDrawerOpen() {
		setMenuDrawerOpen(true);
	}




	const classes = useStyles();

	return (
		<AppBar
			style={{ height: 35 }}
			position='sticky'
			role={'banner'}
			className={clsx(classes.customHeight, classes.appBar,)}
			
		>

			<IconButton
				edge='start'
				className={clsx(classes.menuButton)}
				color='inherit'
				aria-label='open menu drawer'
				onClick={handleDrawerOpen}
			>
				<MenuIcon />
			</IconButton>
			<div className={classes.toolBar}>
				<div className={classes.appNameFull} />
				<div className={classes.appNameFull}>
					{process.env.REACT_APP_UTILITY_NAME}&nbsp;&nbsp;{dcName}
				</div>
				<div className={classes.appNameFull}>
					v{process.env.REACT_APP_VERSION}
				</div>
			</div>
		</AppBar>
	);
}
export const AppHeader: React.FunctionComponent<AppHeaderProps> =
	React.memo(AppHeaderComponent);
